import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout/Layout"
import MainSection from "../../components/MainSection/MainSection"
import Section from "../../components/Section/Section"
import "../../styles/documents.scss"

import QuoteSection from "../../components/QuoteSection/QuoteSection"


//@ts-ignore
import Fade from "react-reveal/Fade"
import Seo from "../../components/Seo/Seo"
import document__theme from "../../images/ilustrations/Documents-Main.svg"
import TrustedDocs from "../../images/ilustrations/Documents-Trusted.svg"
import Rest_API from "../../images/ilustrations/RestAPI.svg"
import Security from "../../images/ilustrations/Security.svg"

import icon_tile_4 from "../../svg/Augment.svg"
import icon_tile_1 from "../../svg/DocForensics.svg"
import icon_tile_3 from "../../svg/Faster.svg"
import icon_tile_2 from "../../svg/Jigsaw.svg"

import doc__step from "../../images/ilustrations/doc__step.png"
import doc__step__1 from "../../images/ilustrations/doc__step__1.png"
import doc__step__2 from "../../images/ilustrations/doc__step__2.png"
import doc__step__3 from "../../images/ilustrations/doc__step__3.png"
import doc__step__4 from "../../images/ilustrations/doc__step__4.png"

import ico__hat from "../../svg/financial_crime.svg"
import ico__doc from "../../svg/NewModels.svg"
import ico__target from "../../svg/RemoveFriction.svg"

import workflow__blurry from "../../images/ilustrations/Blurry_doc.svg"
import workflow__parse from "../../images/ilustrations/Documents-Extract.svg"
import workflow__formats from "../../images/ilustrations/Documents-Files.svg"
import workflow__select from "../../images/ilustrations/Documents-Filter.svg"

import ico__insurance from "../../svg/Insurance.svg"
import ico__onboarding from "../../svg/MagnifyingGlass.svg"
import ico__lending from "../../svg/Money.svg"

import case1 from "../../images/ilustrations/case1.png"
import case2 from "../../images/ilustrations/case2.png"
import case3 from "../../images/ilustrations/case3.png"
import case4 from "../../images/ilustrations/case4.png"


const Documents = () => {

  const [isCase1, setIsCase1] = useState(true)
  const [isCase2, setIsCase2] = useState(false)
  const [isCase3, setIsCase3] = useState(false)
  const [isCase4, setIsCase4] = useState(false)

  useEffect(() => {
    document.addEventListener("scroll", handleScroll)
    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])

  let handleScroll = () => {

    if (typeof window !== "undefined") {
      if (window.scrollY > 2250 && window.scrollY < 2950) {
        setIsCase1(true);
        setIsCase2(false);
        setIsCase3(false);
        setIsCase4(false);
      }
      if (window.scrollY > 2950 && window.scrollY < 3650) {
        setIsCase1(false);
        setIsCase2(true);
        setIsCase3(false);
        setIsCase4(false);
      }
      if (window.scrollY > 3650 && window.scrollY < 4350) {
        setIsCase1(false);
        setIsCase2(false);
        setIsCase3(true);
        setIsCase4(false);
      }
      if (window.scrollY > 4350) {
        setIsCase1(false);
        setIsCase2(false);
        setIsCase3(false);
        setIsCase4(true);
      }

    }
  }

  const [isItemShown1, setIsItemShown1] = useState(true)
  const [isItemShown2, setIsItemShown2] = useState(false)
  const [isItemShown3, setIsItemShown3] = useState(false)

  const handleItemShown1 = () => {
    setIsItemShown1(true)
    setIsItemShown2(false)
    setIsItemShown3(false)
  }

  const handleItemShown2 = () => {
    setIsItemShown1(false)
    setIsItemShown2(true)
    setIsItemShown3(false)
  }

  const handleItemShown3 = () => {
    setIsItemShown1(false)
    setIsItemShown2(false)
    setIsItemShown3(true)
  }

  return (
    <Layout>
      <Seo
        title="Detect Forgeries on Any Type of Document With AI"
        description="Spend less time on false positives, more time catching fraud. Verify invoices, payroll slips, bank statements, and more. Catch forgeries you can't see"
      />
      <div className="Documents__main">

        <MainSection
        caption="Document Forensics"
        title="Detect Document Forgery and "
        titleHighlight="Know Who To Trust"
        text="Authenticate documents at scale and focus on real document fraud with AI."
        className="Career__main"
        showReferences
        divided
        imageLink={document__theme}
        button={{
          link: "/case-studies/habito",
          variant: "blue",
          text: "Read Case Study",
          size: "large",
        }}
      ></MainSection>
      </div>

      <Section
        className="Documents__resistant-docs"
      >
        <div className="w-wrap">
          <div className="w1-2">
            <h2 className=" Documents__resistant-docs__title"><span className="highlighted">Find Fraud</span> the Human Eye Can’t in Loan Applications, Insurance Claims, ID Cards, and More</h2>
            <p>Verify the authenticity of digital documents with machine learning delivered via a simple <strong>REST API</strong>.</p>
          </div>
          <div className="w1-2 Documents__rest__api" >
            <img alt="REST API" src={Rest_API}/>
          </div>
        </div>

        <div className="w-wrap">
          <div className="w1-2 Documents__tiles__item">
            <img alt="Out of the box solution" src={icon_tile_1} className="Documents__icon__tile" />
            <div>
              <h3>Out-of-the-box document fraud detection</h3>
              <p>Detect up to 80% of document forgeries on day one — machine learning training optional.</p>
            </div>
          </div>
          <div className="w1-2 Documents__tiles__item">
            <img alt="Understand every decision" src={icon_tile_2} className="Documents__icon__tile" />
            <div>
              <h3>Understand and justify every decision</h3>
              <p>Boil down complex analysis into simple verdicts to tell the complete story for compliance.</p>
            </div>
          </div>
        </div>

        <div className="w-wrap">
          <div className="w1-2 Documents__tiles__item">
            <img alt="Faster and safer customer approval" src={icon_tile_3} className="Documents__icon__tile" />
            <div>
              <h3>Faster and safer customer approvals</h3>
              <p>99.99% accurate trust models guarantee authenticity so you can confidently automate approvals.</p>
            </div>
          </div>
          <div className="w1-2 Documents__tiles__item">
            <img alt="Designed by cybersecurity experts" src={icon_tile_4} className="Documents__icon__tile" />
            <div>
              <h3>Designed by cybersecurity experts</h3>
              <p>Privacy and security backed by 16 years of experience in fighting cybercrime at scale.</p>
            </div>
          </div>
        </div>

        <div className="w-wrap mt-10 wHeadRow">
            <div className="w2-3">
              <h2 className=" Documents__story__h1">Every document has a <span className="highlighted">story.</span> We&nbsp;tell it over <span className="highlighted">500 different ways.</span></h2>
            </div>
            <div className="w1-3">
              <p>Each document goes through a layered analysis to provide clear, substantiated verdicts in just a few seconds.</p>
            </div>
          </div>

      </Section>

      <Section sectionClass="Documents__story patternedGray">

          <div className="w-wrap Documents__story__item">
            <div className="w1-2">
                <div className="Documents__story__item__cases sticky__image">
                  <img alt="Document" src={doc__step} className="Documents__step__icon" />
                  <img alt="Find modification" src={case1} className={`${isCase1 ? "caseOn" : ""} case`} />
                  <img alt="Detect fraud" src={case2} className={`${isCase2 ? "caseOn" : ""} case`} />
                  <img alt="Layer detection" src={case3} className={`${isCase3 ? "caseOn" : ""} case`} />
                  <img alt="50 models" src={case4} className={`${isCase4 ? "caseOn" : ""} case`} />
                </div>
            </div>
            <div className="w1-2 Documents__story__item__text">

              <div className={`${isCase1 ? "textCaseOn" : ""} textCase`} >
                <img alt="Find modification" className="phoneOnly" src={doc__step__1} />
                <h2><span className="highlighted">500+</span> parameters feed <span className="highlighted">100+</span> different detectors.</h2>
                <p>Find modifications in digital documents, images and scans, print-and-rescan modifications, and more…</p>
              </div>

              <div className={`${isCase2 ? "textCaseOn" : ""} textCase`} >
                <img alt="Detect fraud" className="phoneOnly" src={doc__step__2} />
                <h2>Universal models <span className="highlighted">detect fraud</span> right out of the box.</h2>
                <p>Detect forgery even on documents you’ve never seen before with integrity checks on internal structure, electronic signatures, editor use, and more.</p>
              </div>

              <div className={`${isCase3 ? "textCaseOn" : ""} textCase`} >
                <img alt="Layer detection" className="phoneOnly" src={doc__step__3} />
                <h2>Country- and industry-specific models add another <span className="highlighted">layer of detection</span></h2>
                <p>Our accumulated knowledge of specific markets rapidly increases precision and returns results within seconds.</p>
              </div>

              <div className={`${isCase4 ? "textCaseOn" : ""} textCase`} >
                <img alt="50 models" className="phoneOnly" src={doc__step__4} />
                <h2>Models tailored to your use cases bring <span className="highlighted">unmatched precision</span> to verdicts</h2>
                <p>Just 50 examples will train models that know what your documents should look like, inside and out — with 99.99% precision.</p>
              </div>

            </div>
          </div>


      </Section>

      <Section
        className="Documents__trusted"
      >
        <div className="w-wrap trustedBelt">
          <div className="w1-2">
            <h2>Trusted Documents — <span className="highlighted">Authenticity-as-a-Service</span></h2>
            <p className="mt-10">Flip the script: we guarantee the authenticity of documents we mark as Trusted with <strong>99.99%</strong> accuracy (legal won’t let us say more). If we have a doubt, we flag it for your review.</p>
          </div>
          <div className="w1-2 Documents__trusted__docs">
            <img alt="Trusted documents" className="trustedDocs" src={TrustedDocs} />
          </div>
        </div>

        <div className="w-wrap Documents__tripples">
          <div className="w1-3">
            <img alt="Remove Friction" src={ico__target} />
            <h3 className="mb-2">Remove friction</h3>
            <p className="mt-2">from your approval processes</p>
          </div>
          <div className="w1-3">
            <img alt="Create new models in days" src={ico__doc} />
            <h3 className="mb-2">Create new models in days</h3>
            <p className="mt-2">with just 50 documents</p>
          </div>
          <div className="w1-3">
            <img alt="Focus your risk teams" src={ico__hat} />
            <h3 className="mb-2">Focus your risk teams</h3>
            <p className="mt-2">on real document fraud cases</p>
          </div>
        </div>

      </Section>

      <Section className="Documents__workflows" gray>

          <div className="w-wrap">
              <h2><span className="highlighted">Safely</span> Automate Your Workflows</h2>
          </div>

          <div className="w-wrap Documents__automation">
            <div className="w1-2">
              <img alt="Intake documents" src={workflow__formats} className="Documents__step__icon" />
              <h3>Intake documents in PDF, JPEG, PNG or TIFF</h3>
            </div>

            <div className="w1-2">
              <img alt="Filter" src={workflow__select} className="Documents__step__icon" />
              <h3>Filter out the wrong kinds of document</h3>
            </div>
          </div>

          <div className="w-wrap Documents__automation">
            <div className="w1-2">
              <img alt="Reject documents" src={workflow__blurry} className="Documents__step__icon" />
              <h3>Reject poor quality documents that could hide fraud</h3>
              </div>

            <div className="w1-2">
              <img alt="Extract specific content" src={workflow__parse} className="Documents__step__icon" />
              <h3>Extract specific content from documents</h3>
              </div>
          </div>

      </Section>

      <Section>
          <div className="w-wrap Documents__environment">
            <div className="w1-2">
              <img alt="Security" src={Security} />
            </div>
            <div className="w1-2">
              <h2>A Privacy Environment Built by <span className="highlighted">Security Experts</span></h2>
              <p className="mt-10">We’re so paranoid we don’t trust ourselves.</p>
              <p className="list__item">Minimum privilege principles in all things</p>
              <p className="list__item">Data transfers handled via secure TLS 1.2 connections </p>
              <p className="list__item">Standard AES-256 encryption for at-rest data.</p>
              <p className="list__item">Ephemeral analysis environments</p>
              <p className="list__item">Models trained on images downscaled to 640x480 pixels thumbnails with no PII</p>
            </div>
          </div>
        </Section>
        <Section sectionClass="patternedGray2">
        <div className="Documents__fields">
          <h2>What Can Be Made <span className="highlighted">Resistant?</span></h2>

          <div className="w-wrap">
            <div className="w1-3 Documents__field">
              <img alt="Lending" src={ico__lending} className={`Documents__field__ico ${isItemShown1 ? "Documents__field__ico-active" : ""}`} id="ico__lending" />
              <img alt="Onboarding" src={ico__onboarding} className={`Documents__field__ico ${isItemShown2 ? "Documents__field__ico-active" : ""}`}  id="ico__onboading" />
              <img alt="Insurance" src={ico__insurance} className={`Documents__field__ico ${isItemShown3 ? "Documents__field__ico-active" : ""}`}  id="ico__insurance" />
            </div>
            <div className="w2-3">

              <div onClick={handleItemShown1} className={`Documents__field__item ${isItemShown1 ? "Documents__field__item-active" : ""}`}>
                <div className="Documents__field__item__open">
                  <div className="horizontal"></div>
                  <div className="vertical"></div>
                </div>
                <h3>Lending</h3>
                <p>
                  Up to 5% of loans are based on forged documents. Catch forgeries
                  in <Link to="/case-studies/habito">home loans</Link>, car financing and more before they enter your system — and limit the
                  damages.
                </p>
              </div>

              <div onClick={handleItemShown2} className={`Documents__field__item ${isItemShown2 ? "Documents__field__item-active" : ""}`}>
                <div className="Documents__field__item__open">
                  <div className="horizontal"></div>
                  <div className="vertical"></div>
                </div>
                <h3>Onboarding</h3>
                <p>
                  Distinguish real customers from forged identities at scale on sign up.
                  Remove friction from KYC processes on new customers, and current ones
                  receiving new services.
                </p>
              </div>

              <div onClick={handleItemShown3} className={`Documents__field__item ${isItemShown3 ? "Documents__field__item-active" : ""}`}>
                <div className="Documents__field__item__open">
                  <div className="horizontal"></div>
                  <div className="vertical"></div>
                </div>
                <h3>Insurance claims</h3>
                <p>
                Every 5 minutes, a new insurance fraud is uncovered. Asses invoices supporting
                claims for authenticity in seconds.
                </p>
              </div>

            </div>
          </div>
        </div>

{/*
      <div className="Documents__team">
        <Section
          foregroundColor="white"
          className="Documents__team__section"
          centered
          title="Empower your team"
        >
          <div className="Documents__team__section__text">
            We relieve your operational teams of routine, fully manual document
            verification so that they can concentrate on cases that require
            their expertise.
          </div>
        </Section>
      </div>
*/}

      </Section>
        <Fade up distance="250px">
          <QuoteSection
          quotes={[
            {
              restOfQuote:
                "Resistant AI dramatically reduces false positives and detects advanced fraud and manipulation at the same time. Under the protection of Resistant AI, our Nikita engine can fully focus on credit risk assessment excellence.",
              quoteBy: "Michal Krocil",
              companyName: "Twisto",
              position: "Chief Risk Officer",
              link: "https://www.twisto.cz/",
            },
            {
              restOfQuote:
                "We use Resistant AI’s technology in our KYC process. We greatly value the insights gained to help us validate the authenticity of documents submitted by customers.",
              quoteBy: "Keren Levy",
              companyName: "Payoneer",
              position: "COO",
              link: "https://www.payoneer.com",
            },
            {
              restOfQuote:
                "Resistant AI gives us reliable, real-time insights into our customer data. With high degrees of precision and accuracy.",
              quoteBy: "Dan Hagerty",
              companyName: "Habito",
              position: "CEO - Founder",
              link: "https://www.habito.com/",
            },
            {
              restOfQuote:
                "Resistant AI prevents the manipulation of invoices submitted to our marketplace. It allows our investors to trade in security and saves my team a huge amount of manual work.",
              quoteBy: "Alexandra Belkova",
              companyName: "Roger",
              position: "Head of Operations",
              link: "https://www.roger.cz",
            },
          ]}
          ></QuoteSection>
        </Fade>

    </Layout>
  )
}

export default Documents
